import React, { Component } from 'react';
import TitreH3 from "../../../components/UI/Titres/TitreH3";
import { NavLink } from "react-router-dom";

class PlanDuSite extends Component {
    componentDidMount = () => {
        document.title = "Amarres & Matelots - Plan du site";
    }

    render() {
        return (
            <>
                <TitreH3 bgColor="bg-primary">Plan du site</TitreH3>
                <div className="planContainer">
                    <div className='planDuSite'>
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <NavLink to="/" exact className="nav-link">- Accueil</NavLink>
                                <span className="sr-only">(current)</span>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/bateaux" exact className="nav-link">- Bateaux</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/contact" exact className="nav-link">- Contact</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/mentionsLegales" exact className="nav-link">- Mentions légales</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/plandusite" exact className="nav-link">- Plan du site</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/conditionsUtilisation" exact className="nav-link">- Conditions d’utilisation</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>   
            </>
        );
    }
}

export default PlanDuSite;