import React, { Component } from 'react';
import nouveauBackground from "../../../assets/images/nouveauBackground.png";

class Accueil extends Component {
    componentDidMount = () => {
        document.title = "Amarres & Matelots - Accueil";
    }
    render() {
        return (
                <div className="tocover">
                <img src={nouveauBackground} alt="nouveaubandeau" className="img-fluid" />
                    
                        
                    <div className="superposition">
                        <div className="bulle"></div>
                        <div className="bulle"></div>
                        <div className="bulle"></div>
                        <div className="bulle"></div>
                        <div className="bulle"></div>
                    </div>
                </div>
        );
    }
}

export default Accueil;