import React, { Component } from 'react';
import TitreH3 from "../../../components/UI/Titres/TitreH3";

class Conditions extends Component {
    componentDidMount = () => {
        document.title = "Amarres & Matelots - Conditions d'utilisation";
    }

    render() {
        return (
            <>
                <TitreH3 bgColor="bg-primary">Conditions d'utilisation</TitreH3>
                <div className="container">
                <h5>1 – Acceptation :</h5>
Tout accès au site web de www.amarresetmatelots.fr et/ou toute recherche effectuée sur ledit site, et/ou toute utilisation dudit site et /ou tout téléchargement d’une partie du contenu dudit site par l’utilisateur vaut acceptation des Conditions d’Utilisation du site web de www.amarresetmatelots.fr, telles que définies ci-après.

<h5>2 – Objet du site web :</h5>
Toutes les informations contenues sur le site web de www.amarresetmatelots.fr, et plus généralement l’ensemble des éléments, incluant notamment, les logiciels, données, textes, graphismes, images, sons, vidéos, logos, symboles, code html ci-après dénommé le “Contenu”, ne sont mises à la disposition des utilisateurs qu’à titre indicatif. Elles ne peuvent en aucun cas être interprétées comme une offre commerciale, une licence, un conseil, une relation professionnelle entre l’utilisateur et l’auteur de ce site. Le contenu fourni sur ce site n’exonère pas l’utilisateur de procéder par lui-même au contrôle de l’information fournie.
Il est précisé que le contenu fourni sur ce site peut faire référence à des produits ou services qui ne sont pas disponibles en France.<br/><br/>

<h5>3 – Liens hypertextes avec des sites tiers :</h5>
L’auteur de ce site n’exerce aucun contrôle quant au contenu des sites tiers. L’existence d’un lien hypertexte entre le site web de www.amarresetmatelots.fr et un site tiers ne signifie pas que l’auteur assume une quelconque garantie et responsabilité quant à son contenu ou l’usage qui peut en être fait.
Il incombe à l’utilisateur de prendre les précautions nécessaires pour s’assurer de l’absence de virus sur le site visité.
Il est interdit, sans l’accord écrit et préalable de l’auteur du site à tout utilisateur du site web www.amarresetmatelots.fr, de créer, sur n’importe quel autre site web, tout lien hypertexte y compris un lien hypertexte dit “profond” avec une page du site web de www.amarresetmatelots.fr, et de le combiner avec la technique du “framing” ou toute autre technique similaire permettant de renvoyer directement aux pages secondaires du site web de www.amarresetmatelots.fr, sans passer par sa page d’accueil.<br/><br/>

<h5>4 – Droits de propriété intellectuelle :</h5>
Ce site web est protégé par les droits de propriété intellectuelle, incluant, sans toutefois s’y limiter, les marques, droits d’auteur, dessins, le droit sui generis du producteur de la base de données, etc. et est la propriété exclusive de l’auteur de www.amarresetmatelots.fr. Son contenu, incluant, sans toutefois s’y limiter, textes, données, graphiques, images, sons, vidéos, logos, icônes, code html, est protégé par le droit de la propriété intellectuelle et reste la propriété de l’auteur ou de tiers.
Vous pouvez copier, télécharger et imprimer ce contenu à des fins personnelles et non commerciales, conformément aux principes régissant la législation sur la propriété intellectuelle. Toute autre utilisation du contenu du site web www.amarresetmatelots.fr, sans son autorisation écrite préalable est interdite. Par conséquent, vous ne devez en aucun cas modifier le contenu de ce site. Le non-respect des interdictions susmentionnées peut constituer un acte de contrefaçon et / ou de concurrence déloyale engageant votre responsabilité civile et / ou pénale.
Toutes les autres marques qui n’appartiennent pas à l’auteur du site web et qui apparaissent sur le site Web www.amarresetmatelots.fr appartiennent à leurs propriétaires respectifs, qui peuvent ou non être affiliés, liés ou parrainés par l’auteur. Vous devez demander une autorisation spécifique pour utiliser à quelque fin que ce soit l’une des marques.
Si vous avez connaissance d’une violation des droits de propriété intellectuelle de www.amarresetmatelots.fr, ou si vous pensez que des éléments du site Web de www.amarresetmatelots.fr ont porté atteinte à vos droits de propriété intellectuelle, veuillez envoyer un courrier électronique à :
etienndour@gmail.com
Veillez à inclure autant de détails que possible (par exemple : une description des droits de propriété intellectuelle présumés enfreints, une description de la localisation du contenu que vous revendiquez, etc.). Notez que nous n’ouvrirons aucune pièce jointe quelles que soient les circonstances.<br/><br/>

<h5>5 – Garanties et responsabilités :</h5>
Le contenu du site www.amarresetmatelots.fr est fourni ” en l’état “, sans garantie d’aucune sorte, ni expresse, ni tacite autre que celle prévue par la loi en vigueur, et notamment sans garantie que le contenu réponde aux besoins de l’utilisateur ni que le contenu soit à jour.
Bien que l’auteur s’efforce de fournir un contenu fiable sur son site web, il ne garantit pas que son site web soit exempt d’inexactitudes, d’erreurs typographiques, d’omissions et /ou de virus. L’auteur se réserve à tout moment et sans préavis le droit d’apporter des améliorations et/ou des modifications au contenu de son site web.
L’auteur ne garantit pas les résultats liés à l’utilisation d’un logiciel ou d’une information disponible sur son site web. L’utilisateur est seul responsable de toute utilisation des informations contenues sur ce site. 
L’auteur ne pourra pas être tenu responsable des dommages indirects résultant de l’usage de ce site web ou d’autres sites qui lui sont liés, notamment et sans limitation, tout préjudice financier ou commercial, perte de programme ou de données du système d’information ou autre de l’utilisateur, même si l’auteur a eu connaissance de la possibilité de survenance de tels dommages. En tout état de cause, la responsabilité de l’auteur pour tout dommage direct ou en lien avec l’utilisation de son site web ou tout autre site qui lui est lié ne pourra dépasser un montant équivalent à mille (1.000) euros.<br/><br/>

<h5>6 – Données personnelles et cookies :</h5>
Ce site ne collecte pas de données personnelles.<br/><br/>

<h5>7 – Avertissement aux utilisateurs :</h5>
L’auteur n’assume aucune obligation de suivi des informations que l’utilisateur pourrait déposer sur le site web. A ce titre, l’utilisateur garantit que n’importe quelle information, ou élément (le terme “élément” désigne tout projet, fichier ou autres pièces jointes envoyés) ou commentaires autres que des données personnelles, que l’utilisateur peut transmettre à l’auteur par le site we, ne viole aucun droit de propriété intellectuelle d’un tiers ou une autre loi applicable. De telles informations, ou éléments ou commentaires, seront traités comme non-confidentiels et non-propriétaires. En soumettant ainsi n’importe quelle information, élément ou commentaire, l’utilisateur donne à l’auteur de ce site tout droit nécessaire, de manière illimité et irrévocable, pour employer, exécuter, montrer, modifier et transmettre de telles informations, éléments ou commentaires, y compris n’importe quelle suggestion, concept ou savoir-faire. L’auteur de ce site se réserve le droit d’employer de telles informations, éléments ou commentaires de n’importe quelle façon de son choix.<br/><br/>

<h5>8 – Loi applicable – Indépendance des clauses :</h5>
Toute difficulté, réclamation ou litige provenant ou lié(e) aux présentes Conditions d’Utilisation sera régi(e) selon la loi française. LE TRIBUNAL DE COMMERCE DE RENNES SERA SEUL COMPETENT. Si n’importe quelle disposition de ces conditions d’utilisation est tenue pour être illégale, invalide ou inapplicable, en application d’une loi, d’un règlement ou à la suite d’une décision définitive d’une juridiction compétente, les dispositions restantes resteront en vigueur.<br/><br/>

<h5>9 – Modification des conditions d’utilisation :</h5>
L’auteur se réserve le droit de modifier les conditions d’utilisation de son site web à tout moment et sans préavis. Les utilisateurs seront automatiquement liés par ces modifications quand ils navigueront sur ce site web et devront périodiquement lire les conditions d’utilisation.<br/><br/>

                
                </div>   
            </>
        );
    }
}

export default Conditions;